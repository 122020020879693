export const SUPPORT_PAGE_ACCUEIL = "https://tableaudebord-apprentissage.atlassian.net/servicedesk/customer/portal/3";

export const GESTION_COMPTE_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/14`;
export const GESTION_ORGANISME_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/8`;
export const TRANSMISSION_DONNEES_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/11`;
export const EFFECTIFS_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/9`;
export const SIFA_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/12`;
export const AUTRE_GROUP = `${SUPPORT_PAGE_ACCUEIL}/group/10`;

export const GC_AIDE_CREATION_ELEMENT_LINK = `${GESTION_COMPTE_GROUP}/create/36`;
export const GC_SUPPRESSION_COMPTE_ELEMENT_LINK = ` ${GESTION_COMPTE_GROUP}/create/52`;
export const GC_AUTRE_ELEMENT_LINK = `${GESTION_COMPTE_GROUP}/create/53`;

export const GO_MODIFICATION_IDENTITE_ELEMENT_LINK = `${GESTION_ORGANISME_GROUP}/create/31`;
export const GO_MODIFICATION_RELATION_ELEMENT_LINK = ` ${GESTION_ORGANISME_GROUP}/create/64`;
export const GO_AUTRE_ELEMENT_LINK = `${GESTION_ORGANISME_GROUP}/create/11`;

export const TD_PARAMETRAGE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}/create/39`;
export const TD_API_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}/create/48`;
export const TD_MANUEL_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}/create/56`;
export const TD_COMPRENDRE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}/create/44`;
export const TD_AUTRE_ELEMENT_LINK = `${TRANSMISSION_DONNEES_GROUP}/create/57`;

export const EFF_ERREUR_ELEMENT_LINK = `${EFFECTIFS_GROUP}/create/63`;
export const EFF_AFFICHAGE_ELEMENT_LINK = `${EFFECTIFS_GROUP}/create/46`;
export const EFF_EDITION_ELEMENT_LINK = `${EFFECTIFS_GROUP}/create/42`;
export const EFF_AUTRE_ELEMENT_LINK = `${EFFECTIFS_GROUP}/create/58`;

export const SIFA_EFFECTIF_INCORRECT_ELEMENT_LINK = `${SIFA_GROUP}/create/30`;
export const SIFA_REJET_ELEMENT_LINK = `${SIFA_GROUP}/create/54`;
export const SIFA_AUTRE_ELEMENT_LINK = `${SIFA_GROUP}/create/59`;

export const AUTRE_AMELIORATION_ELEMENT_LINK = `${AUTRE_GROUP}/create/55`;
export const AUTRE_ANOMALIE_ELEMENT_LINK = `${AUTRE_GROUP}/create/49`;
export const AUTRE_MODIF_RESEAU_ELEMENT_LINK = `${AUTRE_GROUP}/create/79`;
